import React from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  graphql
} from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import NewsRoll from "../components/NewsRoll"

import higuchi from "../img/team/higuchi.gif";
import nara from "../img/team/nara.gif";
import saito from "../img/team/saito.gif";
import takahashi_t from "../img/team/takahashi_t.gif";
import takeuchi from "../img/team/takeuchi.gif";
import yasuhara from "../img/team/yasuhara.gif";
import yota from "../img/team/yota.gif";
import maruta from "../img/team/maruta.gif";
import ishibashi from "../img/team/ishibashi.gif";






const About = () => (
  <Layout>
    <section className="section section--gradient">
      <div className="content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <p className="has-text-weight-semibold is-size-4 has-text-centered">
              メンバー紹介</p>
            <p className="has-text-weight-normal has-text-centered"style={{marginBottom: `80px`}}>
              INPUTは、長岡出身のメンバーによって構成されています。</p>
            <p className="has-text-weight-semibold is-size-5 has-text-centered">
              機械班</p>
            <div className="content">
              <div className="columns is-multiline">
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={takahashi_t} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">高橋知也</p>
                    <p className="has-text-weight-nornal is-size-7 has-text-left">東北大学 大学院卒。<br />長岡工業高等専門学校卒。<br />RoboCup2019 Rescue実機リーグ総合優勝。<br />ロボカップジュニア2016 世界大会出場。</p>
                    <span class="tag is-info">副リーダー</span>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={higuchi} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">樋口翔太</p>
                    <p className="has-text-weight-nornal is-size-7 has-text-left">筑波大学 大学院所属。<br />長岡工業高等専門学校卒。<br />ロボカップジュニア2017 世界大会優勝。<br />孫正義育英財団3期生。</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={saito} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">斎藤祐功</p>
                    <p className="has-text-weight-nornal is-size-7 has-text-left">長岡技術科学大学 大学院所属。<br />長岡工業高等専門学校卒。</p>
                    <span class="tag is-info">産学官連携</span>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={yota} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">酒井陽太</p>
                    <p className="has-text-weight-nornal is-size-7 has-text-left">長岡工業高等専門学校専攻科卒。<br />ロボカップジュニア2018 世界大会準優勝。</p>
                  </div>
                </div>
              </div>
            </div>
            <p className="has-text-weight-semibold is-size-5 has-text-centered"
              style={{ marginTop: `60px` }}>
              回路班</p>
            <div className="content">
              <div className="columns is-multiline">
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={yasuhara} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">安原雅貴</p>
                    <p className="has-text-weight-nornal is-size-7 has-text-left">長岡技術科学大学 大学院所属。<br />長岡工業高等専門学校卒。<br />ロボカップジュニア2017 世界大会優勝。</p>
                    <span class="tag is-info">リーダー</span>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={nara} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">奈良貴明</p>
                    <p className="has-text-weight-nornal is-size-7 has-text-left">東北大学 大学院所属。<br />東京農工大学卒。<br />長岡工業高等専門学校卒。<br />第3回廃炉創造ロボコン文部科学大臣賞。</p>
                  </div>
                </div>
                {/* <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={noimage} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">酒井龍一</p>
                    <p className="has-text-weight-nornal is-size-7 has-text-left">長岡工業高等専門学校専攻科所属。<br />ロボカップジュニア2018 世界大会準優勝。</p>
                  </div>
                </div> */}
              </div>
            </div>
            <p className="has-text-weight-semibold is-size-5 has-text-centered"
              style={{ marginTop: `60px` }}>
              AI班</p>
            <div className="content">
              <div className="columns is-multiline">
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={takeuchi} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">竹内系太郎</p>
                    <p className="has-text-weight-nornal is-size-7 has-text-left">長岡技術科学大学 大学院卒。<br />長岡工業高等専門学校卒。<br />ロボカップジュニア2018 世界大会準優勝。</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={maruta} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">丸田裕輝</p>
                    <p className="has-text-weight-nornal is-size-7 has-text-left">長岡技術科学大学 大学院卒。<br />長岡工業高等専門学校卒。</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={ishibashi} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">石橋和貴</p>
                    <p className="has-text-weight-nornal is-size-7 has-text-left">長岡工業高等専門学校専攻科卒。<br />ロボカップジュニア2018 世界大会準優勝。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default About